import React from "react"
import { graphql, navigate } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { homePagePaths, translate } from '../translate'
import Header from "../components/header"
import Footer from "../components/footer"
import Title from "../components/title"
import LeadershipPage from "../components/pages/leadership-page"

const PageLeadership = ({data, location, pageContext}) => {
    const post = data?.nodePageTramuntana
    const leadership = data?.nodeLeadershipTramuntana
    const team = data?.team
    const english = data?.english
    const spanish = data?.spanish
    const translationPaths = {
     en: english?.path?.alias,
     es: spanish?.path?.alias,
   }
    return (
      <Layout
        location={location}
        langcode={post.langcode}
        translationPaths={translationPaths}
      >
      <Seo
          title={post.title}
          lang={post.langcode}
          description={post.body.summary}
          image={post?.relationships?.field_image?.image_style_uri?._653_432}
        />
        <Header
          lang={pageContext?.langcode}
          location={location}
         />
          <LeadershipPage
            post={post}
            lang={post.langcode}
            //events={events}
            bannerHeight="420px"
            //ministries={ministries}
            //promoted={promoted}
            //buttonBanner="Vull saber més"
            //toknowus={toknowus}
            leadership={leadership}
            team={team}
           />
        <Footer
          lang={pageContext?.langcode}
        />
      </Layout>
    )
}

export const pageQuery = graphql`
query PageLeadership ($drupal_internal__nid: Int, $langcode: String!) {
    nodePageTramuntana (
      drupal_internal__nid: {eq: $drupal_internal__nid},
      langcode:{eq: $langcode}
      ){
        title
        body {
          processed
          summary
          value
        }
        relationships{
          field_image{
            image_style_uri {
              _294x192
              _541x359
              _653_432
              large
              medium
              thumbnail
              wide
            }
          }
        }
      }
      team: allNodeTeamTramuntana(filter: { langcode: { eq: $langcode } }) {
        nodes {
          langcode
          title
          field_email
          field_role
          field_team_type
          relationships {
            field_image {
              image_style_uri {
                large
                medium
                thumbnail
                wide
                _294x192
                _541x359
                _653_432
              }
            }
          }
        }
      }
    nodeLeadershipTramuntana (langcode: {eq: $langcode}){
        field_team_title
        field_team_body {
          processed
          value
        }
        relationships {
          field_document {
            uri {
              url
              value
            }
            filename
          }
          field_team_image {
            image_style_uri {
              _294x192
              _541x359
              _653_432
              large
              medium
              thumbnail
              wide
            }
          }
          field_council_image {
            image_style_uri {
              _294x192
              _541x359
              _653_432
              large
              medium
              thumbnail
              wide
            }
          }
        }
        field_council_title
        field_council_body {
          processed
          value
        }
        field_council_cta {
          title
        }
      }
}
`

export default PageLeadership
